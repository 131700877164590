import React from 'react';

/**
 * Images/SVG
 */
import Background from '../images/webDevH.jpg';
import WebDevOne from '../images/webDev.jpg';
import WebDevTwo from '../images/webDev2.png';

/**
 * Components
 */
import SEO from '../components/seo/seo';
import Layout from '../components/layout/layout';
import Banner from '../components/default-banner/default-banner';
import DynamicTextBlock from '../components/dynamic-text-block/dynamic-text-block';

export default () => {

  const innerBannerData = {
    backgroundImg: { backgroundImage: `url(${Background}` },
    mainTitle: 'Web Development London',
    mainText: ''
  };


  const dataBlockOne = {
    left: true,
    img: <img alt="animation" src={WebDevOne}/>,
    title: 'How A Web Development Company London Can Help You Out',
    textPieceOne: 'In this digital era, every business is moving to online platforms. It allows them to reach millions of people and helps them create a positive image of the brand. If you also own a business, getting a website for it will be the first step you can take towards success. This is where a web development company London will guide you. Here are some benefits that you can obtain by taking the assistance of Radium experts.',
    list: [],
    listAfterLine: '',
    textPieceTwo: ''
  };

  const dataBlockTwo = {
    left: true,
    img: '',
    title: 'Optimal Website Design',
    textPieceOne: 'Designing a business website seems like a simple task now because of the available tools. However, you must know that not every site works similarly. Despite looking similar, one can draw tremendous traffic, and the other will do no benefit to you. Our experts ensure here that your business gets the desired results without any hassle. They build the website in a way to benefit your company as highly as possible.',
    list: [],
    listAfterLine: '',
    textPieceTwo: ''
  };

  const dataBlockThree = {
    left: false,
    img: <img alt="animation" src={WebDevTwo}/>,
    title: 'Feature Incorporation',
    textPieceOne: 'It isn’t necessary for you to include the exact features on your site as there are on your competitor’s website. According to your target audience, some features can get dropped while new ones can get added. Our web development London designers give special attention to this factor. They ensure your site remains highly simplified for the visitors, and they find the necessary features in place. This increases the overall user experience on the website.',
    list: [],
    listAfterLine: '',
    textPieceTwo: ''
  };

  const dataBlockFour = {
    left: true,
    title: 'Scalability',
    textPieceOne: 'Your business requirements change with each passing day. That is why you can’t expect your website to work in the same way after five years like it is working now, without making significant changes in it. However, you need not worry about this factor while handing over the work to us. Our web development company London has the motto of minimizing your efforts as much as possible. Therefore, your website will adapt to the upcoming changes and advancements side by side. This way, you won’t have to get into the hassle of continuous redesigning.',
    list: [],
    listAfterLine: '',
    textPieceTwo: ''
  };

  const dataBlockFive = {
    left: true,
    title: 'Result-Oriented Work',
    textPieceOne: 'Unlike any other web development company London, we don’t think our job is done as soon as we handover the fully developed website to you. We function with user insights and analysis data in order to see whether or not your site is working accurately. Hence, you will be able to get the desired results without much hassle.',
    list: [],
    listAfterLine: '',
    textPieceTwo: ''
  };

  return (
    <Layout>
      <SEO 
        title="About" 
        keywords={[ `digital`, `agency`, `technology`, `Radium`, `About` ]} 
      />
      <Banner {...innerBannerData}/>
      <DynamicTextBlock {...dataBlockOne}/>
      <DynamicTextBlock {...dataBlockTwo}/>
      <DynamicTextBlock {...dataBlockThree}/>
      <DynamicTextBlock {...dataBlockFour}/>
      <DynamicTextBlock {...dataBlockFive}/>
      <div className="container spacing-bottom">
        <i>All in all, we can say that our primary objective is to work with you and not for you. Thus, we provide web development London solutions to make your business succeed digitally.</i>
      </div>
    </Layout>
  )
};
